import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import { HTTP } from '@ionic-native/http/ngx';


/* PARA ABRIR A CONEXÃO COM O SERVIDOR QUE ESTA ARMAZENANDO AS API's */


@Injectable()
export class PostProvider{
    server: string = "http://ionic.di-data-tech.com.br/"; /*  DIRETÓRIO */
    

    constructor(private http : HttpClient){

    }

    /* METODO PARA INSERIR AS API's */
    
    inserirApi(dados: any, api: string){

        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          };
       
            let url = this.server + api ;
           return this.http.post(url, JSON.stringify(dados), httpOptions)
            .map(res => res);     
    
  }
 

}


